import React from 'react';
import { Header } from '@goodfynd/react-web.layout.header';

import config from '../../../config';
import { useApp } from '../../../context/AppContext';
import type { LandingHeaderProps } from '../types';

export default function LandingHeader({
  logo_type,
  ...props
}: LandingHeaderProps) {
  const { isLoggedIn } = useApp();

  return (
    <Header
      {...props}
      hide_navigation
      isAuthenticated={isLoggedIn}
      logoEnvironment={config.env.NAME_LOWER}
      logoType={logo_type}
      shadow={false}
    />
  );
}
