import React, { useCallback } from 'react';
import type { FooterProps } from '@goodfynd/react-web.layout.footer';
import { Footer } from '@goodfynd/react-web.layout.footer';
import Head from 'next/head';
import { useRouter } from 'next/router';

import { MainContentContainer } from '../../components/cms/styles';
import { LandingHeader } from '../../components/landing/LandingHeader';
import type { LandingHeaderProps } from '../../components/landing/types';
import LayoutFull from '../../components/layout/LayoutFull';
import config from '../../config';
import { useCmsPage } from '../../hooks';
import { setPageTitle } from '../../utils/base';
import {
  renderCmsBodyComponent,
  renderCmsTitleComponent,
} from '../../utils/cms-util';
function CmsPage() {
  const {
    query: { slug },
  } = useRouter();
  const { body, seo, header, title, footer } = useCmsPage(slug as string);

  const renderBody = useCallback(
    () =>
      body?.map(({ fields, type }, index) =>
        renderCmsBodyComponent(index, type, fields)
      ),
    [body]
  );

  const renderTitle = useCallback(
    () => (
      <>
        {title?.map(({ fields, type }, index) =>
          renderCmsTitleComponent(index, type, fields)
        )}
      </>
    ),

    [title]
  );

  return (
    <>
      {seo && (
        <Head>
          <title>{setPageTitle(seo?.title)}</title>
          <meta content={seo?.description} name="description" />
        </Head>
      )}

      <div style={{ paddingTop: header ? 64 : 0 }}>
        {header && (
          <LandingHeader {...(header as unknown as LandingHeaderProps)} />
        )}

        <MainContentContainer>
          {renderTitle()}

          {renderBody()}
        </MainContentContainer>

        {footer?.[0] && (
          <Footer
            drift_interaction_id={config.env.DRIFT_INTERACTION_ID}
            {...(footer[0] as unknown as FooterProps)}
          />
        )}
      </div>
    </>
  );
}

CmsPage.getLayout = function getLayout(page: React.ReactElement) {
  return <LayoutFull>{page}</LayoutFull>;
};

export default CmsPage;
